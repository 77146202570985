import { ToastContainer } from 'react-toastify';
import { Montserrat } from '@next/font/google';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { SWRConfig } from 'swr';

import { starJediCyrillic, icomoon } from '../common/utils/fonts';

import type { AppProps } from 'next/app';
import { AuthProvider } from 'common/hooks/useAuth';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

const montserrat = Montserrat({
  subsets: ['latin', 'cyrillic'],
  weight: ['400', '500', '600', '700', '800', '900'],
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
      language="ua">
      <AuthProvider>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
          }}>
          <style jsx global>{`
            :root {
              --font-montserrat: ${montserrat.style.fontFamily};
              --font-star-jedi: ${starJediCyrillic.style.fontFamily};
              --font-icomoon: ${icomoon.style.fontFamily}
          `}</style>
          <ToastContainer theme="dark" autoClose={3000} />
          <GoogleAnalytics trackPageViews strategy="lazyOnload" />
          <Component {...pageProps} />
        </SWRConfig>
      </AuthProvider>
    </GoogleReCaptchaProvider>
  );
}

export default MyApp;
