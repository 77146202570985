const routes = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  confirmEmail: '/confirm-email',
  recoverEmail: '/recover-email',
  verifyEmail: '/verify-email',
  dashboard: '/dashboard',
  settings: '/settings',
  404: '/404',
  thankYou: '/thank-you',
  resetPassword: '/auth/reset-password',
  registration: '/auth/registration',
  profile: '/profile',
  lecture: (courseId: string, lectureId: string) =>
    `/courses/${courseId}/lectures/${lectureId}`,
};

export default routes;
