import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  ActionCodeInfo,
  User,
  UserCredential,
  applyActionCode,
  checkActionCode,
  isSignInWithEmailLink,
  sendEmailVerification,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  updatePassword,
  updateEmail,
  verifyBeforeUpdateEmail,
} from 'firebase/auth';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { translate, AuthUK } from 'firebase-error-translator';

import { auth, db } from 'common/configs/firebase';

import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { confirmPasswordReset } from 'firebase/auth';
import routes from 'common/utils/routes';

/* Local constants & types
============================================================================= */
export type ResponseError = {
  error: FirebaseError;
};

type SignInType = {
  email: string;
  password: string;
};

type SignUpType = {
  name: string;
  email: string;
  password: string;
  photoURL: string;
  courseID: string;
};

type AuthContextProps = {
  isInitialized: boolean;
  user: User | null;
  signUp: (
    props: SignUpType
  ) => Promise<DocumentReference<DocumentData> | ResponseError | void>;
  signIn: (props: SignInType) => Promise<User | ResponseError | void>;
  signOut: () => Promise<ResponseError | void>;
  signInWithGoogle: () => Promise<unknown>;
  signInAnonymous: () => Promise<unknown>;
  sendPasswordResetEmail: (email: string) => Promise<ResponseError | void>;
  confirmThePasswordReset: (
    oobcode: string,
    newPassword: string
  ) => Promise<ResponseError | void>;
  sendSignInLinkToEmail: (email: string) => Promise<ResponseError | void>;
  sendEmailVerification: (email: string) => Promise<ResponseError | void>;
  confirmUserEmail: (oobCode: string) => Promise<ResponseError | void>;
  checkUserEmail: (
    oobCode: string
  ) => Promise<ResponseError | ActionCodeInfo | void>;
  signInWithEmailLink: (
    email: string
  ) => Promise<ResponseError | UserCredential | void>;
  updateUser: (data: Partial<User>) => Promise<ResponseError | void>;
  updatePassword: (newPassword: string) => Promise<ResponseError | void>;
  updateEmail: (newEmail: string) => Promise<ResponseError | void>;
  verifyBeforeUpdateEmail: (newEmail: string) => Promise<ResponseError | void>;
};

export const AuthContext = createContext<AuthContextProps>({
  isInitialized: false,
  user: null,
  signUp: async () => undefined,
  signIn: async () => undefined,
  signOut: async () => undefined,
  signInWithGoogle: async () => undefined,
  signInAnonymous: async () => undefined,
  sendPasswordResetEmail: async () => undefined,
  confirmThePasswordReset: async () => undefined,
  sendSignInLinkToEmail: async () => undefined,
  sendEmailVerification: async () => undefined,
  confirmUserEmail: async () => undefined,
  checkUserEmail: async () => undefined,
  signInWithEmailLink: async () => undefined,
  updateUser: async () => undefined,
  updatePassword: async () => undefined,
  updateEmail: async () => undefined,
  verifyBeforeUpdateEmail: async () => undefined,
  // loading: true,
});

const handleErrorMessage = (error: FirebaseError): ResponseError => {
  return {
    error: {
      ...error,
      message: translate(error, AuthUK).toClient,
    },
  };
};

const getAuthUrl = () => {
  const origin = window.location.origin;

  return origin;
};

/* <AuthProvider />
============================================================================= */
export const useAuthProvider = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const getUserAdditionalData = async (user: User) => {
    const docRef = collection(db, 'users');
    const userDocRef = doc(docRef, user.uid);

    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      setUser((prevState) => ({ ...prevState, ...(docSnap.data() as User) })); // TODO: hardcoded type here
    } else {
      // doc.data() will be undefined in this case
      // console.log('No such document!');
    }
  };

  const handleAuthStateChanged = async (user: User | null) => {
    setUser(user);

    if (user) {
      await getUserAdditionalData(user);
    }

    setIsInitialized(true);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);

    return unsubscribe;
  }, []);

  const createUser = async ({
    displayName,
    photoURL,
    uid,
    courseID,
  }: Partial<User> & { courseID: string }) => {
    const newUser = {
      uid,
      displayName,
      photoURL,
    };

    const progressInit = {
      course_id: doc(db, `/courses/${courseID}`),
      date_created: new Date(),
      date_modified: new Date(),
      disabled: false,
      lectures_completed: [],
    };

    try {
      const usersRef = doc(db, 'users', uid!);
      const progressRef = doc(db, 'users', uid!, 'progress', courseID);

      const response = await setDoc(usersRef, newUser);
      await setDoc(progressRef, progressInit); // TODO: I think setUser and setProgress should be separated to catch errors

      return response;
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const signUp = async ({ name, password, photoURL, courseID }: SignUpType) => {
    if (!user?.uid) {
      return;
    }

    try {
      await updateUserPassword(password);

      const newUser = await createUser({
        uid: user.uid,
        displayName: name,
        photoURL,
        courseID,
      });

      return newUser;
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const signIn = async ({ email, password }: SignInType) => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      setUser(response.user);
      getUserAdditionalData(response.user);

      return response.user;
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const response = await signInWithPopup(auth, new GoogleAuthProvider());
      // console.log('GOOGLE response', response);
      return response;
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const signInAnonymous = () => signInAnonymously(auth);

  const resetPassword = async (email: string) => {
    try {
      const response = await sendPasswordResetEmail(auth, email);

      return response;
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const confirmUserPasswordReset = async (
    oobCode: string,
    newPassword: string
  ) => {
    if (!oobCode && !newPassword) return; //TODO: throw custom error instead

    try {
      await await confirmPasswordReset(auth, oobCode, newPassword);
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const sendSignInLink = async (email: string) => {
    // set up return URL (where we will redirect the user)
    const settings = {
      url: `${getAuthUrl()}${routes.registration}?email=${email}`,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, settings);
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const sendVerification = async (email: string) => {
    // set up return URL (where we will redirect the user)
    const settings = {
      url: `${getAuthUrl()}${routes.registration}`,
      handleCodeInApp: true,
    };

    try {
      await sendEmailVerification({ email } as User);
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const confirmUserEmail = async (oobCode: string) => {
    if (!oobCode) return;

    try {
      const response = await applyActionCode(auth, oobCode);
      return response;
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const checkUserEmail = async (oobCode: string) => {
    if (!oobCode) return;

    try {
      const response = await checkActionCode(auth, oobCode);
      return response;
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const signInWithLink = async (email: string) => {
    // TODO: validate signin email link
    // if (!isSignInWithEmailLink(auth, getAuthUrl())) {
    //   return;
    // }

    try {
      const response = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );

      return response;
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const updateUser = async (data: Partial<User>) => {
    if (!auth.currentUser?.uid) return; // TODO: trigger error message

    try {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);

      await updateDoc(userDocRef, data);

      setUser((prevState) => ({ ...prevState, ...(data as User) }));
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const updateUserPassword = async (newPassword: string) => {
    if (!auth.currentUser) return; // TODO: trigger error message

    try {
      await updatePassword(auth.currentUser, newPassword);
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const updateUserEmail = async (newPassword: string) => {
    if (!auth.currentUser) return; // TODO: trigger error message

    try {
      await updateEmail(auth.currentUser, newPassword);
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  const verifyBeforeUpdateUserEmail = async (newEmail: string) => {
    if (!auth.currentUser) return; // TODO: trigger error message

    try {
      await verifyBeforeUpdateEmail(auth.currentUser, newEmail);
    } catch (error: unknown) {
      if (!(error instanceof FirebaseError)) {
        throw error;
      }

      return handleErrorMessage(error);
    }
  };

  return {
    isInitialized,
    user,
    signUp,
    signIn,
    signOut: logout,
    signInWithGoogle,
    signInAnonymous,
    sendPasswordResetEmail: resetPassword,
    confirmThePasswordReset: confirmUserPasswordReset,
    sendSignInLinkToEmail: sendSignInLink,
    confirmUserEmail,
    checkUserEmail,
    sendEmailVerification: sendVerification,
    signInWithEmailLink: signInWithLink,
    updateUser,
    updatePassword: updateUserPassword,
    updateEmail: updateUserEmail,
    verifyBeforeUpdateEmail: verifyBeforeUpdateUserEmail,
  };
};

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
